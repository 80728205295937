import { defineStore } from 'pinia';

export const useHeaderStore = defineStore('header', {
	state: () => ({
		logo: {},
		menu: [],
	}),
	actions: {
		init(header) {
			if (!header) return;

			this.menu = header.menu;
			this.logo = {
				src: header.logo,
				alt: header.alt,
			};
		},
	},
});
